import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import * as PizZip from 'pizzip';
import * as docxtemplater from 'docxtemplater';


const RecordsTable = () => {
  
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'descending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('all');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://bavaria-job.com:35100/api/data');
        setData(response.data);
        setTotalPages(Math.ceil(response.data.length / recordsPerPage));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [recordsPerPage]);

 

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({ key, direction });
  };
  const compareValues = (a, b) => {
    if (isNumeric(a) && isNumeric(b)) {
      return parseFloat(a) - parseFloat(b);
    } else if (isDate(a) && isDate(b)) {
      return new Date(a) - new Date(b);
    } else {
      return String(a || '').localeCompare(String(b || ''));
    }
  };

  const isNumeric = (value) => {
    // Check if the value is numeric
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const isDate = (value) => {
    // Check if the value is a valid date string
    return !isNaN(Date.parse(value));
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'ascending') {
      return compareValues(aValue, bValue);
    } else {
      return compareValues(bValue, aValue);
    }
  });

  const filterByColumn = (record) => {
    if (selectedColumn === 'all') {
      return Object.values(record).some(
        (value) => value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      return (
        record[selectedColumn] &&
        record[selectedColumn].toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  };

  const filteredData = sortedData.filter(filterByColumn);
  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / recordsPerPage));
  }, [filteredData, recordsPerPage]);

  const [expandedRecords, setExpandedRecords] = useState([]);

  const toggleRecordExpansion = (recordId) => {
    setExpandedRecords(prevExpanded => {
      if (prevExpanded.includes(recordId)) {
        return prevExpanded.filter(id => id !== recordId);
      } else {
        return [...prevExpanded, recordId];
      }
    });
  };

  const renderHeaderCell = (key, label, width) => (
    <TableCell onClick={() => handleSort(key)} sx={{ cursor: 'pointer', position: 'relative', width: `${width}%`, textAlign: 'center', color:'white' }}>
      {label}
      {sortConfig.key === key && (
        <div style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)' }}>
          {sortConfig.direction === 'ascending' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </div>
      )}
    </TableCell>
  );

  const handleRemoveRecord = async (id) => {
    const shouldDelete = window.confirm('Czy na pewno usunąć wpis?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }
    try {
      await axios.delete(`https://bavaria-job.com:35100/api/data/${id}`);
      // Update the data after successful deletion
      const updatedData = data.filter((record) => record.ID !== id);
      setData(updatedData);
    } catch (error) {
      console.error('Error removing record:', error);
    }
  };
  const [goToPage, setGoToPage] = useState('');

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);

    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGoToPage('');
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      // Handle invalid page number (e.g., show an error message)
      console.error('Invalid page number');
    }
  };

  const handleDownloadCV = async (fileName) => {
    try {
      const response = await fetch(`https://bavaria-job.com:35100/api/downloadCV/${fileName}`);

      if (response.ok) {
        // Create a Blob from the response and trigger a download
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      } else {
        console.error('Error downloading CV:', response.statusText);
        // Handle the error (e.g., display an error message to the user)
      }
    } catch (error) {
      console.error('Error downloading CV:', error);
      // Handle the error (e.g., display an error message to the user)
    }
  };
  const handleDownloadRecord = async (record) => {
    try {
      // Load the template using fetch
      const templateResponse = await fetch('Bavaria-job.docx');
      const templateContent = await templateResponse.arrayBuffer();
      const zip = new PizZip(templateContent);
      const doc = new docxtemplater(zip);
  
      // Set template variables
      doc.setData({
        ID: record.ID,
        timestamp: format(new Date(record.timestamp), 'yyyy-MM-dd'),
        date: format(new Date(record.date), 'yyyy-MM-dd'),
        job: record.job,
        qualifications: record.qualifications,
        skills: record.skills,
        english: record.english,
        german: record.german,
        italian: record.italian,
        french: record.french,
        licence: record.licence,
        car: record.car,
        pay: record.pay,
        name: record.name,
        surname: record.surname,
        age: record.age,
        phone: record.phone,
        email: record.email,
      });
  
      // Compile the document
      doc.render();
  
      // Convert the document to a blob with the correct MIME type
      const blob = doc.getZip().generate({ type: 'blob', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  
      // Trigger a download
      saveAs(blob, `formularz_${record.ID}.docx`);
    } catch (error) {
      console.error('Error downloading record:', error);
      // Handle the error (e.g., display an error message to the user)
    }
  };
  

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const paginatedData = filteredData.slice(startRecordIndex, endRecordIndex);
 
  
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        
        <label htmlFor="columns" style={{ marginLeft: '10px', marginRight: '5px' }}>
          Szukaj w:
        </label>
        <select style={{height:"3vh"}} id="columns" value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
          <option value="all">wszędzie</option>
          <option value="ID">ID</option>
                <option value="name">Imię</option>
          <option value="surname">Nazwisko</option>
          <option value="timestamp">Data złożenia</option>
          <option value="date">Data zaczęcia</option>
          <option value="job">Zawód</option>
          <option value="qualifications">Kwalifikacje</option>
          <option value="skills">Umiejętności</option>
          <option value="english">Angielski</option>
          <option value="german">Niemiecki</option>
          <option value="italian">Włoski</option>
          <option value="french">Francuski</option>
          <option value="licence">Prawo Jazdy</option>
          <option value="car">Samochód</option>
          <option value="pay">Oczekiwania finansowe</option>
    
          <option value="age">Wiek</option>
          <option value="phone">Telefon</option>
          <option value="email">E-mail</option>
          <option value="cv">CV</option>
        </select>
        <input
          type="text"
          placeholder="Wyszukaj..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ margin: '10px' }}
        />
        <label  htmlFor="recordsPerPage" style={{ marginLeft: '10px', marginRight: '5px' , height:"3vh"}}>
          Wyświetl:
        </label>
        <select
          id="recordsPerPage"
          value={recordsPerPage}
          onChange={(e) => setRecordsPerPage(Number(e.target.value))}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
        <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"auto", marginRight:"auto"}}>
        
        
  {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => {
    const isCurrentPage = page === currentPage;
    const isInRange = page >= currentPage - 2 && page <= currentPage + 2;
    const isNearFirstPage = page <= 1;
    const isNearLastPage = page >= totalPages;

    if (isCurrentPage || isInRange || isNearFirstPage || isNearLastPage) {
      return (
        <Button
          key={page}
          variant={isCurrentPage ? 'contained' : 'outlined'}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Button>
      );
    }

    return null;
  })}
  <input
          id="goToPage"
          type="text" inputmode="numeric"
          value={goToPage}
          onChange={(e) => setGoToPage(e.target.value)}
          placeholder='...'
          style={{ marginLeft: '10px', marginRight: '10px' , width:"3vw", textAlign:"center" }}
        />
        <Button variant="outlined" onClick={handleGoToPage}>
          Przejdź
        </Button>
</div>

      </div>
      
      <TableContainer component={Paper} >
        <Table>
          <TableHead sx={{backgroundColor: "rgb(25, 118, 210)"}}>
            <TableRow sx={{color: "white"}}>
              {renderHeaderCell('ID', 'ID', 5)}
              {renderHeaderCell('name', 'Imię', 10)}
              {renderHeaderCell('surname', 'Nazwisko', 10)}
              {renderHeaderCell('timestamp', 'Data złożenia', 10)}
              {renderHeaderCell('date', 'Data zaczęcia', 10)}
              {renderHeaderCell('job', 'Zawód', 10)}
           
         
              <TableCell sx={{ width: '10%' }}></TableCell>
              <TableCell sx={{ width: '10%' }}></TableCell>
              <TableCell sx={{ width: '10%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((record) => (
          
              <React.Fragment key={record.ID}>
                <TableRow
                  key={record.ID}
                >
                  {/* Display only the ID, name, surname, and job fields */}
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{record.ID}</TableCell>
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{record.name}</TableCell>
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{record.surname}</TableCell>
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{format(new Date(record.timestamp), 'yyyy-MM-dd')}</TableCell>
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{format(new Date(record.date), 'yyyy-MM-dd')}</TableCell>
                  <TableCell onClick={() => toggleRecordExpansion(record.ID)} sx={{ textAlign: "center" }}>{record.job}</TableCell>
                  <TableCell  sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleRemoveRecord(record.ID)}
                    >
                      Usuń
                    </Button>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {record.cv && (
                      <Button sx={{ color: record.cv === "brak" ? "red" : "rgb(25, 118, 210)", borderColor: record.cv === "brak" ? "red" : "rgb(25, 118, 210)" }} variant="outlined" onClick={() => handleDownloadCV(record.cv)}>
                      Pobierz CV
                    </Button>
                    
                    )}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Button variant="outlined" onClick={() => handleDownloadRecord(record)}>
                      Pobierz Formularz
                    </Button>
                  </TableCell>
                </TableRow>
                {expandedRecords.includes(record.ID) && (
                  <TableRow key={`${record.ID}-details`} sx={{ backgroundColor: "#e0e0e0" }}>
                  <TableCell  colSpan={21}>
                    {/* Nested Table to display the information in two rows */}
                    <Table sx={{ width: "100%", }}>
                      <TableBody>
                      <TableRow  sx={{verticalAlign:"top" }}>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" colSpan={3} ><strong>Kwalifikacje: <br></br></strong> {record.qualifications}</TableCell>
                          
                        </TableRow>
                        <TableRow>
                        <TableCell sx={{fontSize:"0.9rem"}} width="25%" colSpan={3}  ><strong>Umiejętności:<br></br></strong> {record.skills}</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell sx={{fontSize:"0.9rem"}} colSpan={2} width="25%" ><strong>Oczekiwania finansowe:</strong> {record.pay}</TableCell>
                        </TableRow>

                        <TableRow>
                        <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Zawód:</strong> {record.job}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Prawo Jazdy:</strong> {record.licence}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Samochód:</strong> {record.car}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Wiek:</strong> {record.age}</TableCell>
                       
                          
               
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Angielski:</strong> {record.english}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Niemiecki:</strong> {record.german}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Włoski:</strong> {record.german}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Francuski:</strong> {record.french}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>ID:</strong> {record.ID}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Data złożenia:</strong> {format(new Date(record.timestamp), 'yyyy-MM-dd')}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Data zaczęcia:</strong> {format(new Date(record.date), 'yyyy-MM-dd')}</TableCell>
                          
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>CV:</strong> {record.cv}</TableCell>
               
                        </TableRow>
                        
                        <TableRow>
                        <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Imię:</strong> {record.name}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Nazwisko:</strong> {record.surname}</TableCell>
                          <TableCell sx={{fontSize:"0.9rem"}} width="25%" ><strong>Telefon:</strong> {record.phone}</TableCell>
                          <TableCell  sx={{fontSize:"0.9rem"}} width="25%" ><strong>E-mail:</strong> {record.email}</TableCell>

                        </TableRow>
                        


                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                  
                )}
              </React.Fragment>
            ))}
           
          </TableBody>
        </Table>
      </TableContainer>
      



    </div>
  );
};

export default RecordsTable;
