// Dashboard.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import RecordsTable from './RecordsTable';
import CompanyTable from './CompanyTable'; // Import the CompanyTable component
import ManualRecordForm from './ManualRecordForm';

const Dashboard = () => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const [currentView, setCurrentView] = useState('records'); // 'records', 'companies', or 'addRecord'

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return null;
  }

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const renderContent = () => {
    switch (currentView) {
      case 'records':
        return <RecordsTable />;
      case 'companies':
        return <CompanyTable />;
      case 'addRecord':
        return <ManualRecordForm />;
      default:
        return <RecordsTable />;
    }
  };

  return (
    <div>
      <div style={{marginTop: "2.5vh", marginRight: "1vw"}}>
        <Button 
          sx={{
            width: "7vw", 
            mb: "1.25vh", 
            float: "right",
            ml: "1vw"
          }} 
          variant="contained" 
          onClick={() => handleViewChange('addRecord')}
        >
          Wprowadź
        </Button>
        <Button 
          sx={{
            width: "7vw", 
            mb: "1.25vh", 
            float: "right",
            ml: "1vw"
          }} 
          variant="contained" 
          onClick={() => handleViewChange(currentView === 'records' ? 'companies' : 'records')}
        >
          {currentView === 'records' ? 'Firmy' : 'Klienci'}
        </Button>
      </div>
      {renderContent()}
    </div>
  );
};

export default Dashboard;
