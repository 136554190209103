import React, { useState } from 'react';
import { TextField, Button, Box, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';

const ManualRecordForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    job: '',
    email: '',
    date: format(new Date(), 'yyyy-MM-dd'),
  });

  const [file, setFile] = useState(null);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('Wysyłanie...');

    const formDataToSend = new FormData();
    if (file) {
      formDataToSend.append('cv', file);
    }

    const dataToSend = {
      ...formData,
      qualifications: '',
      skills: '',
      english: 'brak',
      german: 'brak',
      italian: 'brak',
      french: 'brak',
      licence: '',
      car: '',
      pay: '',
      age: '',
    };

    Object.keys(dataToSend).forEach(key => {
      formDataToSend.append(key, dataToSend[key]);
    });

    try {
      const response = await axios.post('https://bavaria-job.com:35100/api/submitForm', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setSubmitStatus('Formularz został wysłany pomyślnie!');
        setFormData({
          name: '',
          surname: '',
          phone: '',
          job: '',
          email: '',
          date: format(new Date(), 'yyyy-MM-dd'),
        });
        setFile(null);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('Wystąpił błąd podczas wysyłania formularza.');
    }
  };

  return (
    <>
      <Box sx={{ 
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        minHeight: 'calc(100vh - 120px)' // Subtract header height and footer height
      }}>
        <Paper 
          elevation={1}
          sx={{ 
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#fff',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          <Box component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              p: 4,
              '& .MuiTextField-root': {
                mb: 2
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#fff'
              }
            }}
          >
            <Typography 
              variant="h6" 
              component="h2" 
              sx={{ 
                mb: 3, 
                color: 'black',
                fontSize: '1.25rem',
                fontWeight: 400
              }}
            >
              Dodaj nowy rekord
            </Typography>
            
            <TextField
              name="name"
              placeholder="Imię *"
              value={formData.name}
              onChange={handleInputChange}
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: false }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 } }}
            />
            <TextField
              name="surname"
              placeholder="Nazwisko *"
              value={formData.surname}
              onChange={handleInputChange}
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: false }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 } }}
            />
            <TextField
              name="job"
              placeholder="Zawód *"
              value={formData.job}
              onChange={handleInputChange}
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: false }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 } }}
            />
            <TextField
              name="phone"
              placeholder="Telefon *"
              value={formData.phone}
              onChange={handleInputChange}
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: false }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 } }}
            />
            <TextField
              name="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleInputChange}
              type="email"
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: false }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 } }}
            />
            
            
            <Box sx={{ mb: 3 }}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: 'rgb(25, 118, 210)',
                  '&:hover': {
                    backgroundColor: 'rgb(21, 101, 192)',
                  },
                  textTransform: 'none',
                  borderRadius: 1,
                  py: 1,
                  px: 2
                }}
              >
                Załącz plik
                <input
                  type="file"
                  hidden
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  onChange={handleFileChange}
                />
              </Button>
              {file && (
                <Typography variant="body2" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.6)' }}>
                  {file.name}
                </Typography>
              )}
            </Box>

            <Button 
              variant="contained" 
              type="submit" 
              fullWidth
              sx={{ 
                backgroundColor: 'rgb(25, 118, 210)',
                '&:hover': {
                  backgroundColor: 'rgb(21, 101, 192)',
                },
                textTransform: 'none',
                borderRadius: 1,
                py: 1.5
              }}
            >
              Dodaj rekord
            </Button>

            {submitStatus && (
              <Typography 
                sx={{ 
                  mt: 2, 
                  textAlign: 'center',
                  color: submitStatus.includes('błąd') ? 'error.main' : 'success.main'
                }}
              >
                {submitStatus}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
      <Box 
        sx={{ 
          width: '100%',
          height: '60px',
          backgroundColor: 'rgb(25, 118, 210)',
          position: 'fixed',
          bottom: 0,
          left: 0
        }} 
      />
    </>
  );
};

export default ManualRecordForm; 